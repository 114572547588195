import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

reportWebVitals();

const getAppPath = () => {
  // @ts-ignore
  return process.env.REACT_APP_BUILD_TARGET;
};

const root = ReactDOM.createRoot(document.getElementById('root')!);

import(`./${getAppPath()}/index.tsx`).then((module) => {
  module.default(root);
});
